import { useRouter } from "next/router";
import usePopupActions, {
  GUEST_LOGIN_POPUP,
  INPUT_FORM_POPUP,
  LOGIN_POPUP,
  REGISTER_POPUP,
} from "@/store/actions/popupAction";
import useUserActions from "@/store/actions/UserProfileActions/userAction";
import UserCourseService from "@/Logic/ClientLogic/UserCourseService/UserCourse.service";
import {
  UserProfileFormData,
  UserProfilePopupData,
} from "./useCourseStatusActionMapping.model";
import PaymentService from "@/Logic/ClientLogic/PaymentService/PaymentService";
import UserJobProfileController from "@/Logic/ClientLogic/UserJobProfile.controller";
import useScroll from "@/hooks/useScroll";
import { toast } from "react-toastify";
import ClientAppManager from "@/Logic/ClientLogic/Services/ClientAppManager";
import { FLOWTYPES } from "@/Logic/ClientLogic/Services/UserFlowService/UserFlowService.types";
import CourseLeadSourceEnum from "@/outscal-commons-frontend/Enums/CourseLeadSourceEnum";
import ApolloClientManager from "@/Logic/GraphQL/ApolloClientManager";
import { CREATE_COURSE_LEAD } from "@/Logic/GraphQL/Mutations/courseLeads.mutation";
import UTMManager from "@/Logic/ClientLogic/Services/UTMManager";
import {
  appendAsQueryParam,
  appendLinkIfNotPresent,
  removeHashFromUrl,
} from "@/utils/urlHelper";

const userJobProfileObj = new UserJobProfileController();

const useCourseStatusActionMapping = (currentCourse, paymentType) => {
  const { user, getAndSetUserCourseStatuses, updateUserProfile } =
    useUserActions();

  const { showPopup, hidePopups } = usePopupActions();
  const router = useRouter();
  const { scrollToSectionById } = useScroll();

  const navigate = ({ link }) => {
    router.push(link);
  };

  const takeActionPostStatusUpdate = (updateStatus) => {
    const { link, action } =
      UserCourseService.instance.userCourseController.getActionPostStatusUpdate(
        updateStatus,
        user
      );

    if (link) {
      navigate({ link });
    }

    if (action && actionMapping[action]) {
      actionMapping[action]();
    }
  };

  const setCourseStatusParam = (courseSlug, waitlistSource) => {
    const courseStatusParam = {
      courseSlug,
      waitlistSource,
    };
    localStorage.setItem(
      "courseStatusParam",
      JSON.stringify(courseStatusParam)
    );
  };

  const logoutUserAction = (popupHeading, waitlistSource) => {
    showPopup(GUEST_LOGIN_POPUP, {
      actions: {
        joiningCourse: {
          course_slug: currentCourse.slug,
          course_type: currentCourse.course_type,
          waitlistSource,
        },
      },
      onGuestLoggedIn: (user, userCourseStatus) => {
        UserCourseService.instance.setUpUserCurseController(user);
        takeActionPostStatusUpdate(userCourseStatus?.current_status?.status);
      },
      heading: popupHeading || "Enroll Now",
    });
    setCourseStatusParam(currentCourse.slug, waitlistSource);
  };

  const openUserDetailsPopup = (withEMI) => {
    const formData = {};
    if (!user?.userJobProfile?.name) {
      formData.name = UserProfileFormData.name;
    }
    if (!user?.userJobProfile?.phone_number) {
      formData.phone_number = UserProfileFormData.phone_number;
    }
    if (user?.userJobProfile?.billing_country_name == undefined) {
      formData.billing_country_name = UserProfileFormData.billing_country_name;
    }

    showPopup(INPUT_FORM_POPUP, {
      ...UserProfilePopupData,
      analyticsIntentText: "payment_user_details",
      formBuilder: {
        formData,
        submitText: "Submit",
        onSubmit: (e) => onSubmitUserDetails(e, withEMI),
      },
    });
  };

  const onSubmitSubscriptionForm = async (formValues, link) => {
    const resp = await ApolloClientManager.client.mutate({
      mutation: CREATE_COURSE_LEAD,
      variables: {
        name: formValues.name,
        email: formValues.email,
        utms: UTMManager.instance.lastUtm,
        anonymousUserId: localStorage.getItem("__anon_id")
          ? JSON.parse(localStorage.getItem("__anon_id"))
          : "",
        userType: "LOGOUT-SUBSCRIBER",
        leadSource: CourseLeadSourceEnum.getValue("pricing-section"),
        courseOfInterest: UserCourseService?.instance?.course?.slug,
      },
    });

    await hidePopups();
    if (
      ClientAppManager.instance.timeZone == "Asia/Calcutta" &&
      paymentType == "CONTENT"
    ) {
      makePayment(
        formValues.email,
        { name: formValues?.name, billing_country_name: "IN" },
        true
      );
    } else {
      router.push(link);
    }
  };

  const openSubscriptionFormPopup = (link) => {
    showPopup(INPUT_FORM_POPUP, {
      ...UserProfilePopupData,
      analyticsIntentText: "subscription_payment_user_details",
      formBuilder: {
        formData: {
          email: UserProfileFormData.email,
          name: UserProfileFormData.name,
        },
        submitText: "Submit",
        onSubmit: (e) => onSubmitSubscriptionForm(e, link),
      },
    });
  };

  const onSubmitUserDetails = async (updateProfile, withEMI) => {
    if (updateProfile.billing_country_name) {
      updateProfile.billing_country_name =
        updateProfile.billing_country_name.id;
    }

    hidePopups();

    const updatedProfile = await userJobProfileObj.updateUserProfile(
      updateProfile
    );
    updateUserProfile();
    makePayment(user?.userDetails?.email, updatedProfile, withEMI);
  };

  const onSuccessPayment = async (verifiedStatus) => {
    if (!user?.userDetails) {
      toast.success("Payment Successfull");
      if (verifiedStatus) {
        showPopup(LOGIN_POPUP, {
          heading: "Login To Continue!",
          subHeading:
            "Hey! Your content access has been unlocked , please login to access the content",
        });
      } else {
        showPopup(REGISTER_POPUP, {
          heading: "Create Account!",
          subHeading: "Hey! Please create an account to access the content",
        });
      }
    } else {
      toast.success("Payment Successfull, reloading...");
      setTimeout(() => {
        window.location.reload();
      }, 1000);
    }
  };

  const makePayment = async (email, userJobProfile, withEMI) => {
    try {
      await PaymentService.makeCoursePayment(
        {
          courseSlug: UserCourseService.instance?.course?.slug,
          title: UserCourseService.instance?.course?.title,
        },
        paymentType,
        {
          name: userJobProfile?.name,
          email,
          phoneNumber: userJobProfile?.phone_number,
          billingCountry: userJobProfile?.billing_country_name,
        },
        onSuccessPayment,
        withEMI,
        !user?.userDetails && localStorage.getItem("__anon_id")
          ? JSON.parse(localStorage.getItem("__anon_id"))
          : ""
      );
    } catch (error) {
      console.log(error);
    }
  };

  const actionMapping = {
    logout: ({ popupHeading = "Enroll Now", waitlistSource }) => {
      logoutUserAction(popupHeading, waitlistSource);
    },
    "check-make-payment": async ({ withEMI = false }) => {
      if (
        user?.userJobProfile?.name &&
        user?.userJobProfile?.phone_number &&
        user?.userJobProfile?.billing_country_name
      ) {
        makePayment(user?.userDetails?.email, user?.userJobProfile, withEMI);
      } else {
        openUserDetailsPopup(withEMI);
      }
    },
    "update-status": async (params) => {
      const resp =
        await UserCourseService.instance.userCourseController.updateUserCourseStatus(
          params
        );
      getAndSetUserCourseStatuses();

      if (params?.courseType == "PAID-PROJECT") {
        return;
      }
      ClientAppManager.instance.userFlowManager?.checkAndTriggerFlow(
        FLOWTYPES.JOIN_WAITLIST,
        { waitlistedCourses: [params.courseSlug] }
      );
      takeActionPostStatusUpdate(resp?.current_status?.status);
    },
    "scroll-to-top": () => {
      scrollToSectionById("enroll");
    },
    navigate,
    "scroll-pricing": () => {
      scrollToSectionById("pricing");
    },
    "subscription-flow": async ({ link, isLoggedin, currentStatus }) => {
      link = appendAsQueryParam(router.asPath, link);
      const isQueryUrl = link?.startsWith("?") || link?.startsWith("&");
      const cleanedUrl = removeHashFromUrl(router.asPath);
      const finalUrl = isQueryUrl
        ? appendLinkIfNotPresent(cleanedUrl, link)
        : link;

      if (isLoggedin) {
        if (currentStatus == null || currentStatus == undefined) {
          await actionMapping["update-status"]({
            courseSlug: UserCourseService.instance?.course?.slug,
            waitlistSource: CourseLeadSourceEnum.getValue("pricing-section"),
            courseType: "PAID-PROJECT",
          });
        }

        if (
          ClientAppManager.instance.timeZone == "Asia/Calcutta" &&
          paymentType == "CONTENT"
        ) {
          makePayment(
            user?.userDetails?.email,
            { ...user?.userJobProfile, billing_country_name: "IN" },
            true
          );
        } else {
          router.push(finalUrl);
        }
      } else {
        openSubscriptionFormPopup(finalUrl);
      }
    },
  };

  const takeGivenAction = (action) => {
    if (action && action.name) {
      actionMapping[action.name](action.params);
    }
  };

  return { takeGivenAction };
};

export default useCourseStatusActionMapping;
