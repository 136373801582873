import NetworkManager from "@/outscal-commons-frontend/Managers/NetworkManager";


class UserJobProfileController {
    constructor() { }

    updateUserProfile = async (updateProfile) => {
        try {
            const resp = await NetworkManager.put("/edit-profile", {
                profile: JSON.stringify(updateProfile),
            });

            return resp.profile
        } catch (error) {
            console.log("error : ", error)
        }
    }
}

export default UserJobProfileController;

