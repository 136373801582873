import { NetworkManager } from "@/outscal-commons-frontend";
import RazorpayPaymentController from "./Razorpay.controller";

class PaymentService {
  static CERTIFICATE_TYPE = "CERTIFICATE";
  static COURSE_TYPE = "COURSE";
  #paymentController;
  constructor() {
    this.#paymentController = new RazorpayPaymentController();
  }

  async verifyPayment() {}
  async makeCoursePayment(
    courseDetails,
    paymentType,
    userDetails,
    onSuccessPayment = () => {},
    withEMI = false,
    anonymousUserId
  ) {
    NetworkManager.setLoading(true);
    const { email, phoneNumber, name, billingCountry } = userDetails || {};
    await this.#paymentController.initiatePayment(
      courseDetails,
      paymentType,
      name,
      email,
      phoneNumber,
      billingCountry,
      onSuccessPayment,
      withEMI,
      anonymousUserId
    );
    NetworkManager.setLoading(false);
  }
}

export default new PaymentService();
